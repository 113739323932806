import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Mission from "../../Components/Mission/Mission";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const MissionPage = () => {
  return (
    <>
      <Seo locale="it" page="discover hevelop" />
      <Header lang="it" />
      <HamburgerMenu />
      <Mission lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default MissionPage;
